import Image from "next/image";
import styles from "./Slides.module.css";
import { useCanvasContext } from "@/libs/context/CanvasContext";
import { useEffect,useRef } from "react";
import imageLoader from "@/libs/helpers/imageLoader";
import { useGlobalContext } from "@/libs/context/GlobalContext";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";

type Props = {
    handleCheckSlide: Function;
    checkedSlides: number[];
    setCurrentSlide: Function;
    slideDataApi: any;
    currentSlide: number;
    setCheckedSlides: Function;
    slideId: string;
    addNewSlide: any;
    setCustomSlideDelete: Function;
    setDeleteSlideId?: any;
    setDeleteSlideIndex?: any;
    setImageData: Function;
    updateSlidePosition: Function;
};

export function Slides({
    handleCheckSlide,
    checkedSlides,
    setCurrentSlide,
    slideDataApi,
    currentSlide,
    setCheckedSlides,
    addNewSlide,
    setCustomSlideDelete,
    setDeleteSlideId,
    setDeleteSlideIndex,
    setImageData,
    updateSlidePosition
}: Props) {
    const { setSlideContent, comments } = useCanvasContext();
    const { props: { syncing, enableEditing } } = useGlobalContext();
    const slideRefs = useRef<(HTMLDivElement | null)[]>([]); // Add ref array to store slide elements
    useEffect(() => {
        const currentSlideData = slideDataApi[currentSlide - 1]?.slideData;
         // Scroll selected slide into view when `currentSlide` changes
         if (slideRefs.current[currentSlide - 1]) {
            slideRefs.current[currentSlide - 1]?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        if (currentSlideData) setSlideContent!(currentSlideData);
    }, [currentSlide]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 5 },
            options: { allowScroll: false }, // Disables scrolling while dragging
        }),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (!active || !over || active.id === over.id) return;
        const oldIndex = slideDataApi.findIndex((slide:any) => slide.slideData.slideId === active.id);
        const newIndex = slideDataApi.findIndex((slide:any) => slide.slideData.slideId === over.id);

        // Check if the slide is being moved to the first position (index 0)
        if (newIndex === 0) return; // Do nothing if target index is 0

        if (slideDataApi[oldIndex]?.slideData?.type === "Custom") {
            const newSlideOrder = arrayMove(slideDataApi, oldIndex, newIndex);
            updateSlidePosition(newSlideOrder[newIndex],newSlideOrder[newIndex-1])
            // Implement your slide reordering logic here
            setImageData!(newSlideOrder)
            setCurrentSlide(newIndex+1)
        }
    };

    return (
        <div className={styles.leftSideBar}>
            {!enableEditing && (
                <div className={`${styles.addSlide} mb10`} onClick={addNewSlide}>
                    + Add Slide
                </div>
            )}
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={slideDataApi.map((slide: any) => slide.slideData.slideId)} strategy={verticalListSortingStrategy}>
                    <div className={styles.slidePages}>
                        {slideDataApi.map((slideData: any, idx: number) => (
                            <Slide
                                key={slideData.slideData.slideId}
                                slideData={slideData}
                                idx={idx}
                                isSelected={currentSlide === idx + 1}
                                checkedSlides={checkedSlides}
                                handleCheckSlide={handleCheckSlide}
                                setCurrentSlide={setCurrentSlide}
                                syncing={syncing}
                                enableEditing={enableEditing}
                                setCustomSlideDelete={setCustomSlideDelete}
                                setDeleteSlideId={setDeleteSlideId}
                                setDeleteSlideIndex={setDeleteSlideIndex}
                                comments={comments}
                                isDraggable={slideData.slideData.type === "Custom"}
                                slideRef={(node:any) => (slideRefs.current[idx] = node)}
                            />
                        ))}
                    </div>
                </SortableContext>
            </DndContext>
            
        </div>
    );
}

function Slide({
    slideData,
    idx,
    isSelected,
    checkedSlides,
    handleCheckSlide,
    setCurrentSlide,
    syncing,
    enableEditing,
    setCustomSlideDelete,
    setDeleteSlideId,
    setDeleteSlideIndex,
    comments,
    isDraggable,
    slideRef
}: any) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: slideData.slideData.slideId,
        disabled: !isDraggable,
    });

    function getSlideStatus(comments: any) {
        const statuses = comments.map((comment: any) => comment.status);
        return statuses.includes("Open") ? "open" : statuses.includes("Resolved") ? "resolved" : "";
    }

    const slideComments = comments?.find((comment: any) => comment.slideId === slideData.slideData.slideId)?.data || [];
    const slideStatus = getSlideStatus(slideComments);

    return (
        <div
        ref={(node) => {
            setNodeRef(node);
            slideRef(node); // Use passed ref
        }}
            style={{
                transform: transform ? `translate3d(0, ${transform.y}px, 0)` : undefined, // Restrict x-axis movement
                transition,
            }}
            {...(isDraggable ? attributes : {})}
            {...(isDraggable ? listeners : {})}
            className={`${styles.pageBox} ${isSelected ? styles.active : ""} ${syncing ? "disabled" : ""} ${slideData.slideData.isVisible ? styles.notExcluded : styles.excluded}`}
            onClick={() => !syncing && setCurrentSlide(idx + 1)}
        >
            <div className={styles.slide}>
                <Image
                    width={129}
                    height={87}
                    className="img-responsive"
                    src={slideData.thumbNail}
                    alt="bg"
                    loader={imageLoader}
                />
            </div>
            <div className={styles.pageNo}>
                <span className="font14 text222">{idx < 9 ? `0${idx + 1}` : idx + 1}</span>
                <div className={styles.slideName} title={slideData.slideData.type}>
                    {slideData.slideData.type.length > 10
                        ? `${slideData.slideData.type.substring(0, 10)}...`
                        : slideData.slideData.type}
                </div>
            </div>
            {!enableEditing && slideData.slideData.type === "Custom" && (
                <div
                    className={styles.detIocnbtbox}
                    onClick={(e) => {
                        e.stopPropagation();
                        setCustomSlideDelete(true);
                        setDeleteSlideIndex(idx);
                        setDeleteSlideId(slideData.slideData.slideId);
                    }}
                >
                    <img src="/assets/images/deletbinicon.svg" alt="" />
                </div>
            )}
            <div className={styles.slideStatus}>
                <div className={`${styles.Dot} ${styles[slideStatus]}`}></div>
            </div>
        </div>
    );
}