import React, { useEffect, useRef, useState } from "react";
import * as htmlToImage from "html-to-image";
import libraryService from "@/libs/services/libraryService";
import { useRouter } from "next/router";
import { v4 as uuidv4 } from 'uuid';

// components
import { CommentBox } from "@/components/home/commentBox";
import { Slides } from "@/components/home/slides";
import { ToolBar } from "@/components/home/toolbar";
import Modular from "@/components/modular/Modular";
import StoreProducts from "@/components/storeProducts/home/StoreProducts";
import Services from "@/components/services/home/Services";
import GlobalError from "@/components/popups/globalError/GlobalError"
import Confirm from '@/components/popups/confirm/Confirm'
import Reload from "@/components/popups/reload/Reload";

// helpers
import { API } from "@/libs/helpers/http-client";
import { delay, isTokenExpired, waitForImagesToLoad } from "@/libs/helpers/common";

// context
import { CanvasContext } from "@/libs/context/CanvasContext";
import { useGlobalContext } from "@/libs/context/GlobalContext";

// interfaces
import { IGalleryImages, IProjectDetails } from "@/interfaces/IHomeContent";
import { IShapes } from "@/interfaces";
import {thumbnail} from '../data/defaultThumbnail'

type props = {
  slideDataApi: any;
  projectDetails: IProjectDetails;
  galleryImages: IGalleryImages[];
  editorJson: any[];
  commentsJson: any[];
  customSlidesJson: any[];
  roomId: number;
  data: any;
  module: string;
  roomtype: string;
};

const Home = ({
  slideDataApi,
  projectDetails,
  galleryImages,
  editorJson,
  commentsJson,
  roomId,
  data,
  module,
}: props) => {
  const {
    props: { setLoading, setModule, startZooming, zoomState, setZoomState, setUpdateJsonError, updateJsonError, isOnline, setCommentFlag, enableImageDelete, setEnableImageDelete, setSlideCount, reloadPopup, setDownloading, setSyncing, setPdfZoomState, setEditorZoomState,setEnableTableDelete,enableTableDelete,setReLogin },
  } = useGlobalContext();
  const [currentTool, setCurrentTool] = useState("");
  const [colors, setColors] = useState({ inner: "", outer: "black" });
  const [strokeWidth, setStrokeWidth] = useState<number>(1.5);
  const [canvasImages, setCanvasImages] = useState<any>([]);
  const [slideContent, setSlideContent] = useState<any>(slideDataApi && slideDataApi[0]);
  const [selectedShapeId, setSelectedShapeId] = useState(null);
  const [isMagnifying, setIsMagnifying] = useState(false);
  const [selectedShapes, setSelectedShapes] = useState<number[]>([]);
  const [clipboard, setClipboard] = useState<IShapes[]>([]);
  const [copiedTable, setCopiedTable]=useState({})
  const imageUploadRef = useRef();
  const tableImageUploadRef = useRef();
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [slideData, setSlideData] = useState<any>(slideDataApi);
  const [loadFlag, setLoadFlag] = useState(false);
  const [checkedSlides, setCheckedSlides] = useState<any>([]);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [imageData, setImageData] = useState<any>([]);
  const [downloadTrigger, setDownloadTrigger] = useState<Boolean>(false);
  const ref: any = useRef<HTMLDivElement>(null);
  const [comment, setComment] = useState(false);
  const [comments, setComments] = useState<any>(commentsJson);
  const [customSlideDelete, setCustomSlideDelete] = useState(false)
  const [deleteSlideId, setDeleteSlideId] = useState("")
  const [deleteSlideIndex, setDeleteSlideIndex] = useState("")
  const [activeLowerBox, setActiveLowerBox] = useState(-1);
  const router = useRouter();
  const { project: projectId = "" } = router.query;
  const versionId = router.query.version
  const roomName = router.query.roomname
  // const [roomName, setRoomName] = useState("");
  const [editedJson, setEditedJson] = useState<any>(editorJson || []);
  const allowRequestDqc = checkedSlides.length === slideData.length ? true : false;
  const [selectedCells, setSelectedCells] = useState<string[]>([]);

  useEffect(() => {
    setModule(module);
    if (module == "Modular") {
      const generate = async () => {
        imageData.length !== slideData.length && (await generateImages(slideData));
      };
      generate();
    }

    // const room = localStorage.getItem(`roomName_${roomId}`);
    // if (room) {
    //   setRoomName(room);
    // }
  }, []);

  useEffect(() => {
    const storedShapes = editedJson.find((f: any) => f.slideId === slideContent.slideId)?.data || [];
    if (storedShapes && storedShapes.length > 0) {
      // Find the shapes corresponding to zoomSlide, zoomPdf, and zoomEditor
      const updatedState = storedShapes.find((item: any) => item.tool === "zoomSlide");
      const updatedPdfState = storedShapes.find((item: any) => item.tool === "zoomPdf");
      const updatedEditorState = storedShapes.find((item: any) => item.tool === "zoomEditor");

      // Set the states based on the existing shapes in storedShapes
      if (updatedState) {
        setPdfZoomState({
          ...updatedState,
          tool: "zoomPdf",
          id: slideContent.slideId + "_pdf"
        });
        setEditorZoomState({
          ...updatedState,
          tool: "zoomEditor",
          id: slideContent.slideId + "_editor"
        });
      }

      // If the zoomPdf and zoomEditor states are already stored, use them
      if (updatedPdfState) {
        setPdfZoomState(updatedPdfState);
        setPdfZoomState({
          ...updatedPdfState,
          positionY:updatedPdfState.positionY == 8 ? 0 : updatedPdfState.positionY
        });

        if(!updatedEditorState){
          setEditorZoomState({
            id: slideContent.slideId + "_editor",
            tool: "zoomEditor",
            slideId: slideContent.slideId,
            positionX: 0,
            positionY: 0,
            scale: 1,
            lastScale: 1
          });
        }
      }

      if (updatedEditorState) {
        setEditorZoomState(updatedEditorState);
        if(!updatedPdfState){
          setPdfZoomState({
            id: slideContent.slideId + "_pdf",
            tool: "zoomPdf",
            slideId: slideContent.slideId,
            positionX: 0,
            positionY: 0,
            scale: 1,
            lastScale: 1
          });
        }
      }
      if(!updatedState && !updatedPdfState && !updatedEditorState){
         // Initialize the zoom states if no stored shapes are found
      setPdfZoomState({
        id: slideContent.slideId + "_pdf",
        tool: "zoomPdf",
        slideId: slideContent.slideId,
        positionX: 0,
        positionY: 0,
        scale: 1,
        lastScale: 1
      });

      setEditorZoomState({
        id: slideContent.slideId + "_editor",
        tool: "zoomEditor",
        slideId: slideContent.slideId,
        positionX: 0,
        positionY: 0,
        scale: 1,
        lastScale: 1
      });
      }
    } else {
      // Initialize the zoom states if no stored shapes are found
      setPdfZoomState({
        id: slideContent.slideId + "_pdf",
        tool: "zoomPdf",
        slideId: slideContent.slideId,
        positionX: 0,
        positionY: 0,
        scale: 1,
        lastScale: 1
      });

      setEditorZoomState({
        id: slideContent.slideId + "_editor",
        tool: "zoomEditor",
        slideId: slideContent.slideId,
        positionX: 0,
        positionY: 0,
        scale: 1,
        lastScale: 1
      });
    }
  }, [slideContent.slideId]);

  const handleCheckSlide = (idx: any) => {
    if (checkedSlides.includes(idx)) {
      setCheckedSlides(checkedSlides.filter((id: any) => id !== idx));
    } else {
      setCheckedSlides([...checkedSlides, idx]);
    }
  };

  const generateImages = async (data: string | any[], slideIndex?: number | undefined) => {
    setLoadFlag(false);
    setCommentFlag(false)
    try {
      setLoading(true);
      for (let idx = 0; idx < data.length; idx++) {
        if (slideIndex !== undefined && idx !== slideIndex) {
          continue;
        }

        if (ref.current) {
          setSlideContent(slideData[idx]);
          setCurrentSlide(idx + 1);
          try {
            await waitForImagesToLoad(ref.current);
            await delay(1000);
            const dataUrl = await htmlToImage.toPng(ref.current, { imagePlaceholder: "/assets/images/error.svg" });
            if (dataUrl) {
              setImageData((oldArray: any[]) => {
                const existingIndex = oldArray.findIndex((item: { id: number; }) => item.id === idx);
                if (existingIndex === -1) {
                  return [
                    ...oldArray,
                    {
                      id: idx,
                      slideData: data[idx],
                      thumbNail: dataUrl,
                    },
                  ];
                } else {
                  return [
                    ...oldArray.slice(0, idx),
                    {
                      id: idx,
                      slideData: data[idx],
                      thumbNail: thumbnail,
                    },
                    ...oldArray.slice(idx),
                  ];
                }
              });
            }

          } catch (imageError) {
            console.error(`Error generating image for slide ${idx}: ${imageError}`);
          }
        }
      }
      if (slideIndex) {
        setSlideContent(slideData[slideIndex]);
        setCurrentSlide(slideIndex + 1);
      } else {
        setSlideContent(slideData[0]);
        setCurrentSlide(1);
        console.log("Slide data:", slideData);
      }
    } catch (error) {
      console.error(`Error generating images: ${error}`);
      console.log("Slide data:", slideData);
    } finally {
      setLoading(false);
      setCommentFlag(true)
      console.log("Slide data:", slideData);

    }
  };

  const updateCommentsJson = async (comment: any, slideId: string) => {
    if (isOnline) {
      setSyncing(true);
      try {
        const verisonIsLatest = await BoqVerisonIsLatest(roomId)
        if (verisonIsLatest) {
          const response: any = await API.post(
            `/api/addComments`,
            {
              id: data.id,
              slideId: slideId,
              groupId: comment[0].parent,
              data: comment,
            }
          );
          if (response && response.data) {
            console.log("Comment added successfully")
            setSyncing(false);
          } else {
            setUpdateJsonError(response?.data?.message || "Failed to add comments");
          }
        } else {
          setUpdateJsonError("New version already exist. Please switch to latest version");
        }
      } catch (error:any) {
        setReLogin(true)
          console.error("Error adding comments:", error);
      }
    }
  };

  const BoqVerisonIsLatest = async (roomId: number) => {
    try {
      const response = await API.get(`/api/CheckRoomVersionStatus`, {
        params: {
          roomId,
        },
      });

      if (response.status === 200) {
        return response.data.data.status
      } else {
        console.error(
          "Error fetching response status",
          response.status
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  const updateCustomSlides = async (slide: any) => {
    setLoading(true)
    if (isOnline) {
      setSyncing(true);
      try {
        const verisonIsLatest = await BoqVerisonIsLatest(roomId)
        if (verisonIsLatest) {
          const response: any = await API.post(
            `/api/addCustomSlide`,
            {
              id: data.id,
              slideId: slide.slideId,
              parentId:slideContent.slideId,
              parentGroupId:slideContent.groupId
            }
          );
          if (response && response.data) {
            setSyncing(false);

            // Add the new slide to the custom slides array
           // Update the slide in the custom slides array

           setSlideData((prev: any) => {
            const index = prev.findIndex((s: any) => s.slideId === slideContent.slideId);
          
            // If matching slideId is found, insert the new slide at that index
            if (index !== -1) {
              return [
                ...prev.slice(0, index+1), // Slides before the matched index
                slide,                    // New slide to insert
                ...prev.slice(index+1),     // Slides after the matched index
              ];
            } else {
              // If no matching slideId is found, add the new slide at the end
              return [...prev, slide];
            }
          });

            setLoadFlag(true);
            await delay(2000);
            setLoading(false);
          } else {
            setUpdateJsonError(response?.data?.message || "Failed to add slide");
            setLoading(false);
          }
        } else {
          setUpdateJsonError("New version already exist. Please switch to latest version");
        }
      } catch (error) {
        setUpdateJsonError(error);
        console.error("Error adding slide:", error);
        setLoading(false)
      }
    }
  };
  const addNewSlide = async () => {
    setLoading(true)
    try {
      const uniqueId = `custom_${uuidv4()}`;
      const newSlide = {
        data: [],
        slideId: uniqueId,
        groupId: "custom",
        isVisible: true,
        type: "Custom",
        order: currentSlide + 1
      };

      // Add the new slide to customSlides state
      updateCustomSlides(newSlide)
    } catch (error) {
      console.error("Error adding new slide:", error);
    }
  };

  useEffect(() => {
    loadFlag && generateImages(slideData, currentSlide);
  }, [loadFlag]);

  const downloadPdf = async () => {
    setDownloading(true);
    let timeoutId:any;
  
    try {
      // Step 1: Request to initiate PDF generation and get the jobId
      const estimateResponse = await API.post('/api/downloadPdf', { roomId, projectId, versionId });
      const { jobId, estimatedTimeMs } = estimateResponse.data.response;
      
      if (!jobId) {
        throw new Error("Job ID not received from the server.");
      }
  
      // Function to check PDF status and download
      const checkAndDownloadPdf = async () => {
        try {
          const statusResponse = await API.get('/api/getPdf', {
            params: { jobId },
            responseType: "blob"
          });
  
          if (statusResponse.status === 200 && statusResponse.data.size > 0) {
            // Clear the timeout to prevent further attempts
            clearTimeout(timeoutId);
  
            // Download PDF
            await downloadFile(statusResponse.data, 'application/pdf', `${roomName}_${projectId}_${roomId}_${versionId}.pdf`);
  
            // Prepare and download JSON
            const timestamp = new Date().toISOString();
            const jsonData = {
              timestamp,
              projectId,
              roomId,
              versionId,
              data: editedJson
            };
            const jsonString = JSON.stringify(jsonData, null, 2);
            const jsonBlob = new Blob([jsonString], { type: 'application/json' });
            await downloadFile(jsonBlob, 'application/json', `${roomName}_${projectId}_${roomId}_${versionId}_${timestamp}.json`);
  
            setDownloading(false);
            setDownloadTrigger(false);
          } else {
            // If PDF is not ready, schedule next check
            timeoutId = setTimeout(checkAndDownloadPdf, estimatedTimeMs);
          }
        } catch (error) {
          console.error("Error checking PDF status:", error);
          setDownloading(false);
          // Handle error (e.g., show user message)
        }
      };
  
      // Helper function to download file
      const downloadFile = (blob: Blob, type: string, filename: string): Promise<void> => {
        return new Promise((resolve) => {
          const url = window.URL.createObjectURL(new Blob([blob], { type }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
          window.URL.revokeObjectURL(url);
          resolve();
        });
      };
  
      // Initial check
      timeoutId = setTimeout(checkAndDownloadPdf, estimatedTimeMs);
  
      // Set a maximum timeout (e.g., 30 minutes)
      setTimeout(() => {
        clearTimeout(timeoutId);
        if (setDownloading) {
          setDownloading(false);
          console.warn("PDF generation timed out.");
          // Notify user about timeout
        }
      }, 1800000);
  
    } catch (error) {
      console.error("Error initiating PDF download:", error);
      setDownloading(false);
    }
  };
  const handleCustomDelete = async (id: any, idx: any) => {
    setLoading(true)
    if (isOnline) {
      setSyncing(true);
      try {
        const verisonIsLatest = await BoqVerisonIsLatest(roomId)
        if (verisonIsLatest) {
          const response: any = await API.post(`/api/deleteCustomSlide`, {
            id: data.id,
            slideId: id,
          });
          if (response && response.data && response.data.success) {
            const updatedSlideData = slideData.filter((data: any) => data.slideId != id)
            const updatedImageData = imageData.filter((custom: any) => custom.slideData.slideId != id)
            setImageData(updatedImageData);
            setSlideData(updatedSlideData)
            delay(1000)
            setCurrentSlide(idx);
            setCustomSlideDelete(false);
            setTimeout(() => {
              setLoading(false);
            }, 2000);
            setSyncing(false);
            console.log("Slide deleted successfully")
          } else {
            console.error("Error deleting Slide")
          }
        } else {
          setLoading(false)
          setCustomSlideDelete(false);
          setUpdateJsonError("New version already exist. Please switch to latest version");
        }
      } catch (error) {
        setSyncing(false);
        console.error("Error deleting Slide:", error);
      }

    }
  }

  useEffect(() => {
    setSlideCount(slideData.length)
  }, [slideData])


  const deleteTools = async (BRoomId: string, slideId: string,
    dataIds: any[]) => {
    if (isOnline) {
      // Convert integers in dataIds to strings
      const stringDataIds = dataIds.map(id => typeof id === "number" ? id.toString() : id);
      setSyncing(true);
      try {
        const verisonIsLatest = await BoqVerisonIsLatest(roomId)
        if (verisonIsLatest) {
          const response: any = await API.post(`/api/deleteTools`, {
            roomId: BRoomId,
            slideId: slideId,
            annotationIds: stringDataIds,
          });

          if (response) {
            setSyncing(false);
            console.log("Tools deleted successfully")
          }
        } else {
          setUpdateJsonError("New version already exist. Please switch to latest version");
        }
      } catch (error) {
        setSyncing(false);
        console.error("Error deleting tools:", error);
      }
    }
  }

  const updateSlidePosition = async (slide: any, adjSlide:any) => {
    if (isOnline) {
      setSyncing(true);
      try {
        const verisonIsLatest = await BoqVerisonIsLatest(roomId)
        if (verisonIsLatest) {
          const response: any = await API.post(
            `/api/addCustomSlide`,
            {
              id: data.id,
              slideId: slide.slideData.slideId,
              parentId:adjSlide.slideData.slideId,
              parentGroupId:adjSlide.slideData.groupId
            }
          );
          if (response && response.data) {
            setSyncing(false);
          } else {
            setUpdateJsonError(response?.data?.message || "Failed to add slide");
            setLoading(false);
          }
        } else {
          setUpdateJsonError("New version already exist. Please switch to latest version");
        }
      } catch (error) {
        setUpdateJsonError(error);
        console.error("Error adding slide:", error);
      }
    }
  };

  return (
    <>
      <CanvasContext.Provider
        value={{
          currentTool,
          colors,
          canvasImages,
          selectedShapeId,
          imageUploadRef,
          tableImageUploadRef,
          setSelectedShapeId,
          selectedShapes,
          setSelectedShapes,
          clipboard,
          setClipboard,
          copiedTable, 
          setCopiedTable,
          setCurrentTool,
          setColors,
          setCanvasImages,
          setUploadedImage,
          setSlideContent,
          uploadedImage,
          galleryImages,
          setComment,
          comment,
          projectDetails,
          comments,
          setComments,
          zoomState,
          editedJson,
          setEditedJson,
          isMagnifying,
          setIsMagnifying,
          strokeWidth,
          setStrokeWidth,
          deleteTools,
          updateCommentsJson,
          data,
          BoqVerisonIsLatest,selectedCells, setSelectedCells
        }}
      >
        <ToolBar
          totalSlides={slideData.length}
          setCurrentSlide={setCurrentSlide}
          currentSlide={currentSlide}
          downloadPdf={downloadPdf}
          downloadTrigger={downloadTrigger}
          slideId={slideContent.slideId}
        />
        {module === "Modular" && (
          <Modular
            slideContent={slideContent}
            activeLowerBox={activeLowerBox}
            setActiveLowerBox={setActiveLowerBox}
            projectData={projectDetails}
            currentSlide={currentSlide}
            refss={ref}
            setImageData={setImageData}
          />
        )}

        {module === "StoreProducts" && (
          <StoreProducts
            slideContent={slideContent}
            activeLowerBox={activeLowerBox}
            setActiveLowerBox={setActiveLowerBox}
            data={data}
            refss={ref}
          />
        )}
        {module === "Services" && (
          <Services
            slideContent={slideContent}
            activeLowerBox={activeLowerBox}
            setActiveLowerBox={setActiveLowerBox}
            data={data}
            refss={ref}
          />
        )}
        {module === "Modular" && (
          <Slides
            slideDataApi={imageData}
            handleCheckSlide={handleCheckSlide}
            checkedSlides={checkedSlides}
            setCurrentSlide={setCurrentSlide}
            slideId={slideContent.slideId}
            currentSlide={currentSlide}
            setCheckedSlides={setCheckedSlides}
            addNewSlide={addNewSlide}
            setCustomSlideDelete={setCustomSlideDelete}
            setDeleteSlideId={setDeleteSlideId}
            setImageData={setImageData}
            setDeleteSlideIndex={setDeleteSlideIndex}
            updateSlidePosition={updateSlidePosition}
          />
        )}
        <CommentBox
          slideId={slideContent.slideId}
          activeLowerBox={activeLowerBox}
          setActiveLowerBox={setActiveLowerBox}
          allowRequestDqc={allowRequestDqc}
          parent={slideContent.type.replace(" Cont", "")}
          slideDataApi={slideDataApi}
        />
      </CanvasContext.Provider>
      {updateJsonError && <GlobalError setPopupMessage={setUpdateJsonError} message={updateJsonError} svgIcon="requestRaiseError"></GlobalError>}
      {!isOnline && <GlobalError setPopupMessage={() => { }} message="No Internet Connection" svgIcon="requestRaiseError"></GlobalError>}
      {customSlideDelete && <Confirm setDocDelete={setCustomSlideDelete} handleDelete={() => handleCustomDelete(deleteSlideId, deleteSlideIndex)}></Confirm>}
      {enableImageDelete === "true" && <Confirm setDocDelete={() => setEnableImageDelete("")} handleDelete={() => setEnableImageDelete("confirm")}></Confirm>}
      {enableTableDelete === "true" && <Confirm setDocDelete={() => setEnableTableDelete("")} handleDelete={() => setEnableTableDelete("confirm")}></Confirm>}
      {reloadPopup && <Reload />}
    </>
  );
};
export const getServerSideProps = async (context: any) => {
  const { project, type, room } = context.query;
  // const { req } = context;

  const loginToken: any = JSON.parse(context.req.cookies.loginToken || "{}").token || "";
  // if (!req.headers.referer && loginToken) {
  //   return {
  //     redirect: {
  //       destination: `/home?project=${project}`,
  //       permanent: false,
  //     },
  //   };
  // }
  let data: any;
  let galleryImages: any;
  let flattenedData: any;
 let DummySlide = {
    type: "project Detail",
    data: {},
    slideId: `project_Detail`,
    isVisible:true
  };
  
  if (room && type && !isTokenExpired(loginToken)) {
    data = await libraryService.getJson(room, project, loginToken, type);
    galleryImages =
      (await libraryService.getGallery(project)) || [];
  }
  const projectDetails: IProjectDetails = data?.mainJsonData[0] || {};
  flattenedData = data?.mainJsonData || [];
  const editorJson: any = data?.editorJsons || [];

  const commentsJson: any[] = data?.commentsJson || [];

  if (type !== "Modular" || isTokenExpired(loginToken)) {
    flattenedData = [DummySlide]
  }

  return {
    props: {
      slideDataApi: flattenedData || [],
      projectDetails,
      galleryImages:galleryImages || [],
      editorJson,
      commentsJson,
      data: data || null,
      module: type || "Modular",
      roomId: room
    },
  };
};

export default Home;
