import { useEffect, useState } from "react";
import styles from "./ToolBar.module.css";
import Image from "next/image";
import { useCanvasContext } from "@/libs/context/CanvasContext";
import { useGlobalContext } from "@/libs/context/GlobalContext";
import { API } from "@/libs/helpers/http-client";
import { GalleryPopup } from "../galleryPopup";
import { useRouter } from "next/router";
import Select from "@/components/shared/select/Select";
import ZoomInOut from "../zoomInOut/ZoomInOut";

type props = {
  totalSlides: number;
  setCurrentSlide: Function;
  downloadPdf: Function;
  currentSlide: number;
  downloadTrigger: Boolean;
  slideId: string;
};
export function ToolBar({
  totalSlides,
  setCurrentSlide,
  currentSlide,
  downloadPdf,
  slideId,
}: props) {
  const {
    props: {
      role,
      module,
      showUpdateBtns,
      showReqBtn,
      latestBoqVersion,
      setCommentsFlag,
      setIsValid,
      setLoading,
      setEnableEditing,
      enableEditing,
      selectedZoomTarget,
      setSelectedZoomTarget,
      pdfZoomState,
      setPdfZoomState,
      editorZoomState,
      setEditorZoomState,
      setZoomState,
      startZooming,
      setStartZooming,
      transformComponentRef,
      pdfTransformComponentRef,
    },
  } = useGlobalContext();
  const {
    setCurrentTool,
    currentTool,
    setColors,
    colors,
    selectedShapeId,
    imageUploadRef,
    setUploadedImage,
    setComment,
    projectDetails,
    setIsMagnifying,
    isMagnifying,
    setStrokeWidth,
    strokeWidth,
    selectedShapes,
  } = useCanvasContext();
  const [galleryPopup, setGalleryPopup] = useState(false);
  const router = useRouter();
  const { room: selectedRoomId = "", type = "", version = "" } = router.query;

  const roomId = router.query.room;
  const roomName = router.query.roomname;
  const [selectedModule, setSelectedModule] = useState(module);
  // const [roomType, setRoomType] = useState("")

  const Modules = [
    { id: 1, roomName: "Modular", roomType: roomName },
    { id: 2, roomName: "Services", roomType: roomName },
    { id: 3, roomName: "Store Products", roomType: roomName },
  ];

  const [isallowed, setIsAllowed] = useState(false);
  const [boxHeight, setBoxHeight] = useState({ height: 1000, width: 1000 });

  useEffect(() => {
    // Check if required query parameters are missing
    if (!selectedRoomId || !type || !version) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [selectedRoomId, type, version]);

  useEffect(() => {
    setCommentsFlag(isallowed);
    setEnableEditing(isallowed || !(latestBoqVersion.id == version));
  }, [isallowed]);

  useEffect(() => {
    if (showReqBtn && !showUpdateBtns) {
      if (role === "DQC") {
        setIsAllowed(true);
      } else if (role === "DESIGNER") {
        setIsAllowed(false);
      }
    } else if (showUpdateBtns && !showReqBtn) {
      if (role === "DQC") {
        setIsAllowed(false);
      } else if (role === "DESIGNER") {
        setIsAllowed(true);
      }
    } else {
      setIsAllowed(true);
    }
  }, [showReqBtn, showUpdateBtns, role]);

  const handleCommentClick = () => {
    !enableEditing && setCurrentTool!("comment");
    !enableEditing && setComment!(true);
  };

  const handleChange = (e: any) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (e.target.value > totalSlides) {
        setCurrentSlide(currentSlide);
      } else {
        setCurrentSlide(Number(e.target.value));
      }
    }
  };

  useEffect(() => {
    totalSlides > 0 && setCurrentSlide(currentSlide);
    const ele = document.getElementById(`slide_${currentSlide}`);

    if (ele) {
      ele.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentSlide]);
  const handleGalleryPopup = () => {
    !enableEditing && setGalleryPopup(!galleryPopup);
    if (!galleryPopup) {
      document.body.classList.add("overFlowHidden");
    } else {
      document.body.classList.remove("overFlowHidden");
    }
  };

  useEffect(() => {
    setSelectedModule(module);
  }, [module]);

  const handleFileChange = async (e: any) => {
    e.preventDefault();
    const fileInput = e.target;
    const file = fileInput.files[0];
    const formData = new FormData();
    formData.append("File", file);
    setLoading(true);

    try {
      const response: any = await API.post(`/api/uploadImage`, formData);
      if (response && response.data && response.data.data) {
        setUploadedImage!(response.data.data.filePath);
      } else {
        console.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false);
      fileInput.value = ""; // Reset the input on error
    }
  };
  // useEffect(()=>{
  //   const room = localStorage.getItem(`roomType_${roomId}`)
  //   if (room) {
  //     setRoomType(room)
  //   }
  // },[])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setSelectedZoomTarget((prevState: any) => {
      const newState = { ...prevState, [name]: checked };

      // If this change would uncheck both, revert the change
      if (!Object.values(newState).some((value) => value)) {
        return prevState;
      }

      return newState;
    });
  };
  const downloadImages = () => {
    const slideRef = document.getElementById("pdfEditorBox");
    if (slideRef) {
      const images = slideRef.querySelectorAll("img");
      images.forEach((img) => {
        const imageUrl = img.src;

        // Extract the file extension from the image URL (handle URLs with query parameters)
        const urlWithoutQuery = imageUrl.split("?")[0];
        const extension = urlWithoutQuery.split(".").pop();

        // Trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.href = imageUrl;
        downloadLink.target = "_blank";
        downloadLink.download = `image_${Math.random()
          .toString(36)
          .substr(2, 9)}.${extension}`;
        downloadLink.click();
      });
    }
  };

  useEffect(() => {
    let zoomInterval: any = null;
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.shiftKey) {
        if (event.key === "+") {
          if (!zoomInterval) {
            startZooming && handleZoomIn(); // Immediate zoom in on key press
            zoomInterval = setInterval(() => {
              startZooming && handleZoomIn(); // Continuous zoom while key is held down
            }, 100); // Adjust the interval time as needed
          }
        } else if (event.key === "_") {
          // Shift + Minus
          if (!zoomInterval) {
            startZooming && handleZoomOut(); // Immediate zoom out on key press
            zoomInterval = setInterval(() => {
              startZooming && handleZoomOut(); // Continuous zoom while key is held down
            }, 100); // Adjust the interval time as needed
          }
        }
      }
    };

    const handleKeyUp = () => {
      if (zoomInterval) {
        clearInterval(zoomInterval);
        zoomInterval = null;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [selectedZoomTarget, startZooming]);

  const handleZoomIn = () => {
    const zoomFactor = 0.01;
    if (boxHeight) {
      const updateZoomState = (prevState: any, target: string) => {
        const currentScale = target.includes("_editor")
          ? transformComponentRef.current?.instance?.transformState?.scale ||
            prevState.scale
          : pdfTransformComponentRef.current?.instance?.transformState?.scale ||
            prevState.scale;

        if (
          (selectedZoomTarget.editor &&
            transformComponentRef.current?.instance?.transformState?.scale >=
              4) ||
          (selectedZoomTarget.pdf &&
            pdfTransformComponentRef.current?.instance?.transformState?.scale >=
              4)
        ) {
          return prevState;
        }

        if (startZooming) {
          if (target.includes("_editor") && selectedZoomTarget.editor) {
            transformComponentRef.current?.zoomIn(zoomFactor);
          }
          if (target.includes("_pdf") && selectedZoomTarget.pdf) {
            pdfTransformComponentRef.current?.zoomIn(zoomFactor);
          }
        }
        const newScale = Math.min(prevState.scale + zoomFactor, 4);
        const scaleDiff = newScale - currentScale;

        const viewportWidth = boxHeight.width;
        const viewportHeight = boxHeight.height;

        const newPositionX =
          prevState.positionX - (viewportWidth / 2) * scaleDiff;
        const newPositionY =
          prevState.positionY - (viewportHeight / 2) * scaleDiff;
        const newId = slideId + target;

        return {
          ...prevState,
          id: newId,
          scale:
            target === "_editor"
              ? transformComponentRef.current?.instance?.transformState?.scale
              : pdfTransformComponentRef.current?.instance?.transformState
                  ?.scale,
          positionX: newPositionX,
          positionY: newPositionY,
          lastScale: newScale,
        };
      };

      if (selectedZoomTarget.editor && selectedZoomTarget.pdf) {
        setEditorZoomState((prevState: any) =>
          updateZoomState(prevState, "_editor")
        );
        setPdfZoomState((prevState: any) => updateZoomState(prevState, "_pdf"));
      } else if (selectedZoomTarget.editor) {
        setEditorZoomState((prevState: any) =>
          updateZoomState(prevState, "_editor")
        );
      } else {
        setPdfZoomState((prevState: any) => updateZoomState(prevState, "_pdf"));
      }
    }
  };

  const handleZoomOut = () => {
    const zoomFactor = 0.01;

    if (boxHeight) {
      const updateZoomState = (prevState: any, target: string) => {
        const currentScale = target.includes("_editor")
          ? transformComponentRef.current?.instance?.transformState?.scale ||
            prevState.scale
          : pdfTransformComponentRef.current?.instance?.transformState?.scale ||
            prevState.scale;

        if (
          (selectedZoomTarget.editor &&
            transformComponentRef.current?.instance?.transformState?.scale <=
              1) ||
          (selectedZoomTarget.pdf &&
            pdfTransformComponentRef.current?.instance?.transformState?.scale <=
              1)
        ) {
          return prevState;
        }

        const newScale = parseFloat(
          Math.max(currentScale - zoomFactor, 1).toFixed(2)
        ); // Round to 2 decimal places
        const scaleDiff = newScale - currentScale;

        const viewportWidth = boxHeight.width;
        const viewportHeight = boxHeight.height;

        const newPositionX =
          prevState.positionX - (viewportWidth / 2) * scaleDiff;
        const newPositionY =
          prevState.positionY - (viewportHeight / 2) * scaleDiff;
        const newId = slideId + target;

        if (startZooming) {
          if (target.includes("_editor") && selectedZoomTarget.editor) {
            transformComponentRef.current?.zoomOut(zoomFactor);
          }
          if (target.includes("_pdf") && selectedZoomTarget.pdf) {
            pdfTransformComponentRef.current?.zoomOut(zoomFactor);
          }
        }

        return {
          ...prevState,
          id: newId,
          scale: target.includes("_editor")
            ? transformComponentRef.current?.instance?.transformState?.scale
            : pdfTransformComponentRef.current?.instance?.transformState?.scale,
          positionX: newPositionX,
          positionY: newPositionY,
          lastScale: newScale,
        };
      };

      if (selectedZoomTarget.editor && selectedZoomTarget.pdf) {
        setEditorZoomState((prevState: any) =>
          updateZoomState(prevState, "_editor")
        );
        setPdfZoomState((prevState: any) => updateZoomState(prevState, "_pdf"));
      } else if (selectedZoomTarget.editor) {
        setEditorZoomState((prevState: any) =>
          updateZoomState(prevState, "_editor")
        );
      } else {
        setPdfZoomState((prevState: any) => updateZoomState(prevState, "_pdf"));
      }
    }
  };

  return (
    <>
      {galleryPopup && <GalleryPopup handleGalleryPopup={handleGalleryPopup} />}
      <div className={styles.toolSetBox}>
        <div className={styles.leftToolBox}>
          {module === "Modular" && (
            <div className={styles.arrowNumBox}>
              <div className={styles.arrowNavBox}>
                <button
                  className={styles.arrowBtn}
                  onClick={() =>
                    setCurrentSlide(
                      currentSlide === totalSlides
                        ? totalSlides
                        : currentSlide + 1
                    )
                  }
                >
                  <i className={`${styles.arrow} ${styles.down}`}></i>
                </button>
                <button
                  className={styles.arrowBtn}
                  onClick={() =>
                    setCurrentSlide(currentSlide === 1 ? 1 : currentSlide - 1)
                  }
                >
                  <i className={`${styles.arrow} ${styles.up}`}></i>
                </button>
              </div>
              <div className={styles.pageNumBox}>
                <input
                  className={`${styles.page} ${styles.activePage}`}
                  value={currentSlide}
                  maxLength={2}
                  type="tel"
                  onChange={handleChange}
                  onFocus={handleChange}
                />
                <span className={`${styles.page} ${styles.totalPage}`}>
                  {totalSlides <= 9 ? `0${totalSlides}` : totalSlides}
                </span>
              </div>
            </div>
          )}
          <div className={styles.miniToolKit}>
            <button
              className={styles.tool}
              disabled={enableEditing || role == "DQC"}
            >
              <Image
                width={20}
                id="undo"
                className="undo"
                height={20}
                src="/assets/images/Undobt.svg"
                alt="Undo"
              />
              <span className={styles.toolTitle}>Undo</span>
            </button>
            <button
              className={styles.tool}
              disabled={enableEditing || role == "DQC"}
            >
              <Image
                width={20}
                id="redo"
                className="redo"
                height={20}
                src="/assets/images/Redobt.svg"
                alt="Redo"
              />
              <span className={styles.toolTitle}>Redo</span>
            </button>
            {type == "Modular" && (
              <ZoomInOut
                currentSlide={currentSlide}
                zoomIn={handleZoomIn}
                zoomOut={handleZoomOut}
              />
            )}
            {type == "Modular" && (
              <div className={`${styles.tool} ${styles.tooltopPading}`}>
                <label className={styles.labelsFlex}>
                  <input
                    type="checkbox"
                    name="editor"
                    checked={selectedZoomTarget.editor}
                    onChange={handleCheckboxChange}
                    disabled={!startZooming}
                  />
                  <span className={styles.zoomLables}>Annotations</span>
                  <span className={styles.font10}>
                    {editorZoomState.scale
                      ? editorZoomState.scale.toFixed(2)
                      : ""}
                    x
                  </span>
                </label>
                <label className={styles.labelsFlex}>
                  <input
                    type="checkbox"
                    name="pdf"
                    checked={selectedZoomTarget.pdf}
                    onChange={handleCheckboxChange}
                    disabled={!startZooming}
                  />
                  <span className={styles.zoomLables}>Image</span>
                  <span className={styles.font10}>
                    {pdfZoomState.scale ? pdfZoomState.scale.toFixed(2) : ""}x
                  </span>
                </label>
              </div>
            )}
            <button
              className={`${styles.tool} ${isMagnifying && styles.activeTool}`}
              onClick={() => {
                setCurrentTool!("");
                setIsMagnifying!(!isMagnifying);
                setStartZooming(false);
              }}
            >
              <Image
                width={22}
                height={20}
                src="/assets/images/magGlass.svg"
                alt="frame"
              />
              <span className={styles.toolTitle}>Magnify</span>
            </button>
            <button
              className={`${styles.tool} ${
                (currentTool == "box" ||
                  currentTool == "circle" ||
                  currentTool == "line-black-thin-2") &&
                styles.activeTool
              }`}
              disabled={enableEditing || role == "DQC"}
            >
              <Image
                width={18}
                height={20}
                src="/assets/images/shapes.svg"
                alt="spapes"
              />
              <span className={styles.toolTitle}>Shapes</span>
              <div className={styles.toolbtclilckd}>
                <div className={styles.tollsubboxm}>
                  <div
                    className={styles.tollbtbtfisbt}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("box");
                    }}
                  >
                    <figure>
                      <Image
                        width={16}
                        height={16}
                        src="/assets/images/squaresbbox.svg"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtfisbt}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("box-dotted");
                    }}
                  >
                    <figure>
                      <Image
                        width={16}
                        height={16}
                        src="/assets/images/boxDotted.svg"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtfisbt}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("circle");
                    }}
                  >
                    <figure>
                      <Image
                        width={16}
                        height={16}
                        src="/assets/images/circledownsbbox.svg"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtfisbt}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("circle-dotted");
                    }}
                  >
                    <figure>
                      <Image
                        width={16}
                        height={16}
                        src="/assets/images/circledotted.svg"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </button>
            <button
              className={`${styles.tool} ${
                currentTool == "textbox" && styles.activeTool
              }`}
              disabled={enableEditing || role == "DQC"}
              onClick={() => {
                !enableEditing && setCurrentTool!("");
                !enableEditing && setCurrentTool!("textbox");
              }}
            >
              <Image
                width={20}
                height={20}
                src="/assets/images/addText.svg"
                alt="text"
              />
              <span className={styles.toolTitle}>Text</span>
            </button>
            <button
              className={styles.tool}
              disabled={enableEditing}
              onClick={() => {
                !enableEditing && setCurrentTool!("");
                !enableEditing && setCurrentTool!("select");
              }}
            >
              <Image
                width={20}
                height={20}
                src="/assets/images/selection.svg"
                alt="text"
              />
              <span className={styles.toolTitle}>Area Selection</span>
            </button>
            <button
              className={styles.tool}
              onClick={handleCommentClick}
              disabled={enableEditing}
            >
              <Image
                width={20}
                height={20}
                src="/assets/images/comment.svg"
                alt="comment"
              />
              <span className={styles.toolTitle}>Comments</span>
            </button>
            <button
              className={`${styles.tool} ${
                (currentTool == "line-black-dotted" ||
                  currentTool == "arrow-black-solid" ||
                  currentTool == "line-black-thin-2" ||
                  currentTool == "doubleArrow-double-black-solid" ||
                  currentTool == "dimension-black-arrow" ||
                  currentTool == "dashdimension-dotted-black-arrow") &&
                styles.activeTool
              }`}
              disabled={enableEditing || role == "DQC"}
            >
              <Image
                width={20}
                height={13}
                src="/assets/images/lineHeight.svg"
                alt="lineHeight"
              />
              <span className={styles.toolTitle}>Lines</span>
              <div className={styles.toolbtclilckd}>
                {/* <div
                  className={styles.toolSubMenu}
                  style={{ cursor: "default" }}
                >
                  <div className={styles.tollbtbtcling}>Lines</div>
                  <div className={styles.tollbtbtcling}>Arrows</div>
                  <div className={styles.tollbtbtcling}>Dimension Lines</div>
                  <div className={styles.tollbtbtcling}>Continuous Curves</div>
                  <div className={styles.tollbtbtcling}>Continuous Lines</div>
                  <div className={styles.tollbtbtcling}>Curved Lines</div>
                </div> */}
                <div className={styles.toolSubMenu}>
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("line-black-thin-2");
                    }}
                  >
                    <figure>
                      <Image
                        width={42}
                        height={2}
                        src="/assets/images/tollclicklinelight.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Solid line</span>
                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("line-black-dotted");
                    }}
                  >
                    <figure>
                      <Image
                        width={42}
                        height={2}
                        src="/assets/images/toolclicklinedased.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Dotted line</span>
                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing &&
                        setCurrentTool!("dashdimension-black-arrow");
                    }}
                  >
                    <figure>
                      <Image
                        width={50}
                        height={8}
                        src="/assets/images/dimensionarrow.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Dimension line</span>
                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing &&
                        setCurrentTool!("dashdimension-dotted-black-arrow");
                    }}
                  >
                    <figure>
                      <Image
                        width={50}
                        height={8}
                        src="/assets/images/dimensiondasharrow.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Dotted dimension line</span>
                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("wave");
                    }}
                  >
                    <figure>
                      <Image
                        width={40}
                        height={10}
                        src="/assets/images/wave.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Wave line</span>
                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("dimensiontext");
                    }}
                  >
                    <figure>
                      <Image
                        width={40}
                        height={10}
                        src="/assets/images/dimensionText.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Dimension line with text</span>
                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("dimensiontext-dotted");
                    }}
                  >
                    <figure>
                      <Image
                        width={50}
                        height={11}
                        src="/assets/images/dimensionDottedText.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Dotted dimension line with text</span>
                    </figure>
                  </div>
                </div>
                <div className={styles.toolSubMenu}>
                <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("arrow-black-solid");
                    }}
                  >
                    <figure>
                      <Image
                        width={43}
                        height={8}
                        src="/assets/images/tollclickrgtarrow.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Single head arrow</span>
                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing &&
                        setCurrentTool!("arrow-dotted-black-solid");
                    }}
                  >
                    <figure>
                      <Image
                        width={50}
                        height={8}
                        src="/assets/images/toolclickrightdotted.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Dotted single head arrow</span>
                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing &&
                        setCurrentTool!("doubleArrow-double-black-solid");
                    }}
                  >
                    <figure>
                      <Image
                        width={43}
                        height={8}
                        src="/assets/images/doubleheadarrow.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Double head arrow</span>
                    </figure>
                  </div>
                  
                  
                  {/* <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("wave-dotted");
                    }}
                  >
                    <figure>
                      <Image
                        width={40}
                        height={10}
                        src="/assets/images/wave.svg"
                        alt=""
                      />
                    </figure>
                  </div> */}
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing &&
                        setCurrentTool!(
                          "doubleArrow-dotted-double-black-solid"
                        );
                    }}
                  >
                    <figure>
                      <Image
                        width={43}
                        height={8}
                        src="/assets/images/doubleheaddottedarrow.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Dotted double head arrow</span>
                    </figure>
                  </div>
                 
                </div>
                <div className={styles.toolSubMenu}>
                <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("spline");
                    }}
                    title='currentTool'
                  >
                    <figure>
                      <Image
                        width={40}
                        height={10}
                        src="/assets/images/spline.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Continues curve</span>

                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("spline-dotted");
                    }}
                  >
                    <figure>
                      <Image
                        width={40}
                        height={10}
                        src="/assets/images/splinedotted.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Dotted continues curve</span>
                    </figure>
                  </div>
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("connectinglines");
                    }}
                  >
                    <figure>
                      <Image
                        width={40}
                        height={10}
                        src="/assets/images/connectline.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Continues line</span>
                    </figure>
                  </div>
                
                  
                  
                  <div
                    className={styles.tollbtbtcling}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing &&
                        setCurrentTool!("connectinglines-dotted");
                    }}
                  >
                    <figure>
                      <Image
                        width={40}
                        height={10}
                        src="/assets/images/connectlinedotted.svg"
                        alt=""
                      />
                      <span className={styles.lineTitle}>Dotted continues line</span>
                    </figure>
                  </div>
                </div>
              </div>
            </button>
            {/* <button
              className={`${styles.tool} ${
                (currentTool == "box" ||
                  currentTool == "circle" ||
                  currentTool == "line-black-thin-2") &&
                styles.activeTool
              }`}
              disabled={enableEditing || role == "DQC"}
            >
              <Image
                width={22}
                height={18}
                src="/assets/images/table.svg"
                alt="spapes"
              />
              <span className={styles.toolTitle}>Tables</span>
              <div className={styles.toolbtclilckd}>
                <div className={styles.tollsubboxm}>
                  <div
                    className={`${styles.tollbtbtcling} dFlex gap15 whiteNrp`}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing &&
                        setCurrentTool!("table-electricalDetails");
                    }}
                  >
                    <figure>
                      <Image
                        width={20}
                        height={20}
                        src="/assets/images/table1.svg"
                        alt=""
                      />
                    </figure>
                    <span>Electrical Details</span>
                  </div>
                  <div
                    className={`${styles.tollbtbtcling} dFlex gap15 whiteNrp`}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing &&
                        setCurrentTool!("table-countertopDetails");
                    }}
                  >
                    <figure>
                      <Image
                        width={20}
                        height={20}
                        src="/assets/images/table2.svg"
                        alt=""
                      />
                    </figure>
                    <span>Countertop Details</span>
                  </div>
                  <div
                    className={`${styles.tollbtbtcling} dFlex gap15 whiteNrp`}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("table-scope");
                    }}
                  >
                    <figure>
                      <Image
                        width={20}
                        height={20}
                        src="/assets/images/table3.svg"
                        alt=""
                      />
                    </figure>
                    <span>Scope</span>
                  </div>
                  <div
                    className={`${styles.tollbtbtcling} dFlex gap15 whiteNrp`}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("table-dadoTiles");
                    }}
                  >
                    <figure>
                      <Image
                        width={20}
                        height={20}
                        src="/assets/images/table4.svg"
                        alt=""
                      />
                    </figure>
                    <span>Dado Tiles</span>
                  </div>
                  <div
                    className={`${styles.tollbtbtcling} dFlex gap15 whiteNrp`}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing &&
                        setCurrentTool!("table-plumbingDetails");
                    }}
                  >
                    <figure>
                      <Image
                        width={20}
                        height={20}
                        src="/assets/images/table5.svg"
                        alt=""
                      />
                    </figure>
                    <span>Plumbing Details</span>
                  </div>
                  <div
                    className={`${styles.tollbtbtcling} dFlex gap15 whiteNrp`}
                    onClick={() => {
                      !enableEditing && setCurrentTool!("");
                      !enableEditing && setCurrentTool!("table-default");
                    }}
                  >
                    <figure>
                      <Image
                        width={20}
                        height={20}
                        src="/assets/images/table6.svg"
                        alt=""
                      />
                    </figure>
                    <span>Default</span>
                  </div>
                </div>
              </div>
            </button> */}
            <button
              className={styles.tool}
              disabled={enableEditing || role == "DQC"}
            >
              <div
                className={`${selectedShapes!.length == 0 ? "fadeOut" : ""} ${
                  styles.toolinercolbox
                }`}
              >
                <Image
                  width={18}
                  height={18}
                  src="/assets/images/imgcrooscolour.svg"
                  alt=""
                />
                <Image
                  width={8}
                  height={4}
                  src="/assets/images/Downcrossclour.svg"
                  alt=""
                />
              </div>
              <span className={styles.toolTitle}>Fill</span>
              {selectedShapes!.length > 0 && (
                <div className={styles.toolbtclilckd}>
                  <div className={styles.tollsbCokobox}>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, inner: "none" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/crooscolo1.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, inner: "red" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/redbox.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, inner: "#FDA828" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/yellowbox.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, inner: "rgb(255,255,1)" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/Darkyeollow4.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, inner: "green" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/green5.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, inner: "rgb(66,221,231)" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/skyblue6.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, inner: "rgb(57,105,228)" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/nevyblue7.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, inner: "black" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/black8.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, inner: "#DDF8FE" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/lightBlue.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              )}
            </button>
            <button
              className={styles.tool}
              disabled={enableEditing || role == "DQC"}
            >
              <div
                className={`${selectedShapes!.length == 0 ? "fadeOut" : ""} ${
                  styles.toolinercolbox
                }`}
              >
                <Image
                  width={18}
                  height={18}
                  src="/assets/images/freamclourbox.svg"
                  alt=""
                />
                <Image
                  width={8}
                  height={4}
                  src="/assets/images/Downcrossclour.svg"
                  alt=""
                />
              </div>
              <span className={styles.toolTitle}>Outline</span>
              {selectedShapes!.length > 0 && (
                <div className={`${styles.toolbtclilckd} flexCol`}>
                  <div className={styles.tollsbCokobox}>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, outer: "black" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/crooscolo1.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, outer: "red" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/redbox.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, outer: "#FDA828" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/yellowbox.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, outer: "rgb(255,255,1)" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/Darkyeollow4.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, outer: "green" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/green5.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, outer: "rgb(66,221,231)" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/skyblue6.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, outer: "rgb(57,105,228)" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/nevyblue7.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, outer: "black" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/black8.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, outer: "#DDF8FE" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/lightBlue.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div
                      className={styles.tollBcolointile}
                      onClick={() => {
                        !enableEditing &&
                          setColors!({ ...colors, outer: "#9E9998" });
                      }}
                    >
                      <figure>
                        <Image
                          width={18}
                          height={18}
                          src="/assets/images/grey.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                  <div className={styles.slidecontainer}>
                    <input
                      type="range"
                      min={0.1}
                      max={10}
                      step={0.1}
                      value={strokeWidth}
                      onChange={(e) =>
                        setStrokeWidth && setStrokeWidth(Number(e.target.value))
                      }
                    />
                    <div className={styles.strokeValue}>{strokeWidth}</div>
                  </div>
                </div>
              )}
            </button>
            <button
              className={styles.tool}
              disabled={enableEditing || role == "DQC"}
            >
              <Image
                width={22}
                height={20}
                src="/assets/images/frame.svg"
                alt="frame"
              />
              <span className={styles.toolTitle}>Images</span>
              <div className={styles.toolbtclilckd}>
                <div className={styles.tollsubboxm}>
                  <div className={styles.tollbtbtcling}>
                    <figure>
                      <span
                        className="font10 text000 dispBlok whiteNrp"
                        onClick={() => {
                          !enableEditing && imageUploadRef.current.click(),
                            !enableEditing && setCurrentTool!("image");
                        }}
                      >
                        Your Computer
                      </span>
                    </figure>
                    <input
                      type="file"
                      ref={imageUploadRef}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                  <div
                    className={styles.tollbtbtcling}
                    onClick={handleGalleryPopup}
                  >
                    <figure>
                      <span className="font10 text000 dispBlok whiteNrp">
                        Blocks Gallery
                      </span>
                    </figure>
                  </div>
                </div>
              </div>
            </button>
            <button
              className={styles.tool}
              // disabled={enableEditing}
              onClick={downloadImages}
            >
              <Image
                width={22}
                height={20}
                src="/assets/images/ImageDload.svg"
                alt="frame"
              />
              <span className={styles.toolTitle}>Download Images</span>
            </button>
          </div>
        </div>
        <div className={styles.rightToolBox}>
          {type && (
            <div className={styles.dropDown}>
              <Select
                type="Modules"
                selectedName={selectedModule}
                roomId={selectedRoomId}
                selectedId={type}
                data={Modules}
              />
            </div>
          )}
          {module == "Modular" && (
            <figure
              className={styles.logedIn}
              onClick={() => {
                downloadPdf();
              }}
            >
              <Image
                src="/assets/images/pdf.svg"
                width={20}
                height={18}
                alt="cloud"
              />
            </figure>
          )}
        </div>
      </div>
    </>
  );
}
